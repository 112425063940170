import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function CarousalImages() {
  return (
    <section id='carousel'>
       <Carousel fade>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./images/banner_dhansheera.jpg"
            alt="First slide"
          />
          {/* <Carousel.Caption>
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./images/banner_dhansheera.jpg"
            alt="First slide"
          />
          {/* <Carousel.Caption>
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="./images/banner_dhansheera.jpg"
            alt="First slide"
          />
          {/* <Carousel.Caption>
            <h5>First slide label</h5>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption> */}
        </Carousel.Item>
    </Carousel>
    </section>
   




  )
}

export default CarousalImages;
