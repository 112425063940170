import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaFacebook, FaInstagram } from 'react-icons/fa';  // Importing the icons

function HeaderNavBar() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="#home">
          <img alt="" src="/images/logo.png" />{' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            {/* Add any other navigation items here if needed */}
          </Nav>
          <Nav activeKey='/'>
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About Us</Nav.Link>
            <Nav.Link href="/contact">Contact Us</Nav.Link>
          </Nav>
          {/* Social Media Icons */}
          <Nav>
            <Nav.Link href="https://www.facebook.com/people/Dhansheera/61570449303616/?mibextid=ZbWKwL" target="_blank">
              <FaFacebook size={24} style={{ color: '#4267B2' }} />
            </Nav.Link>
            <Nav.Link href="https://www.instagram.com/dhansheera._/" target="_blank">
              <FaInstagram size={24} style={{ color: '#E1306C' }} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default HeaderNavBar;
